import React from 'react';
import {Link} from 'gatsby';
import CookieConsent from "react-cookie-consent";
import './cookies.css';

function Cookies(){  
    return(
        <CookieConsent
            disableStyles='true'
            buttonText="Jag Förstår"


            containerClasses="cookie_container"
            contentClasses="cookie_content"
            buttonWrapperClasses="cookie_btnWrapper"
            buttonClasses="cookie_btn"
        >
            <div className="text_container" >
                Vi använder cookies för att se till att vi ger dig den bästa upplevelsen på vår hemsida. Om du fortsätter att använda den här webbplatsen kommer vi att anta att du godkänner detta.
                <Link to="/integritet-och-policy"> Läs mer...</Link>
            </div>
        </CookieConsent>
    );
}
export default Cookies;