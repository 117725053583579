import React from "react";
import Cookies from "./cookies/Cookies";
import "./layout.css"

class Layout extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      city: "",
      region: "",
      country_name: ""
    }
  }

  componentDidMount(){
    let time = new Date();
    let session_time = time.getFullYear()+"-"+time.getMonth()+"-"+time.getDate();

    if(session_time !== localStorage.getItem("session_time")){
      //Get user location
      fetch('https://ipapi.co/json')
      .then(res => res.json())
      .then( json => {
          console.log(json);
          this.setState({
            city: json.city,
            region: json.region,
            country_name: json.country_name    
          })

          //Send user location to database
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              city: this.state.city,
              region: this.state.region,
              country_name: this.state.country_name
            })
          };
          fetch('https://excendo.hamraz.se/admin/api/statistic.php', requestOptions);
          localStorage.setItem("session_time", session_time);
      }) 
    }
  }

  render(){
    return (
      <div style={{overflowX: 'hidden'}}>
        <main>{this.props.children}</main>
        <Cookies/>
      </div>
    )
  }
}

export default Layout